import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <path
        className="st215"
        fill="#43B874"
        d="M31.3,55.7C18.1,55.7,7.4,45,7.4,31.8S18.1,8,31.3,8c13.2,0,23.9,10.7,23.9,23.9S44.5,55.7,31.3,55.7
			 M31.3,8.9C18.7,8.9,8.4,19.2,8.4,31.8c0,12.6,10.3,22.9,22.9,22.9c12.6,0,22.9-10.3,22.9-22.9C54.2,19.2,43.9,8.9,31.3,8.9"
      />
      <polygon
        className="st216"
        fill="#2C7B4E"
        points="15.6,15.5 13.4,15.5 10.9,15.5 10.9,21.1 15.6,23 15.6,20.2 20.1,20.2 20.1,54.5 24.8,59.1 
			24.8,20.2 31,20.2 31,15.5 		"
      />
      <path
        className="st217"
        fill="#40B874"
        d="M11.6,20.6v-4.4h18.6v3.2h-6.2v37.9l-3.2-3.2V19.5h-6.1v2.4L11.6,20.6z M10.1,14.7v6.9l6.2,2.5v-3.1h3.1
			v33.9l6.2,6.1V20.9h6.2v-6.2H10.1z"
      />
      <polygon
        className="st216"
        fill="#2C7B4E"
        points="32.1,15.5 32.1,21 28.7,21 28.7,59.1 33.5,54.5 33.5,20.2 38.1,20.2 38.1,49.9 42.8,45.2 
			42.8,20.2 47.3,20.2 47.3,40.7 52,36 52,15.5 		"
      />
      <path
        className="st217"
        fill="#40B874"
        d="M29.4,21.8l3.3,0v32.4l-3.3,3.2V21.8z M32.9,16.2h18.4v19.5l-3.2,3.2V19.4h-6v25.4l-3.2,3.2V19.5h-6
			L32.9,16.2z M31.4,14.7l0,5.5l-3.4,0v40.5l6.3-6V20.9h3.1v30.7l6.2-6.1V20.9h3.1v21.5l6.2-6.1V14.7L31.4,14.7z"
      />
      <polygon
        className="st177"
        fill="#FFFFFF"
        points="15.6,15.5 13.4,15.5 10.9,15.5 10.9,21.1 15.6,23 15.6,20.2 20.1,20.2 20.1,54.5 24.8,59.1 
			24.8,20.2 31,20.2 31,15.5 		"
      />
      <polygon
        className="st177"
        fill="#FFFFFF"
        points="32.1,15.5 32.1,20.9 28.7,20.9 28.7,59.1 33.5,54.5 33.5,20.2 38.1,20.2 38.1,49.9 42.8,45.2 
			42.8,20.2 47.3,20.2 47.3,40.7 52,36 52,15.5 		"
      />
      <polygon className="st217" fill="#40B874" points="28.6,24.6 32.1,20.9 28.5,20.9 		" />
      <rect x="28.4" y="20.8" fill="#40B874" width="3.8" height="0.2" />
    </g>
  </svg>
);

export default IconLoader;
